<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">3D Scanning in the Presence of Specularities</font></div></td>
          </tr>
          <tr>
            <td>Here, we show several examples of 3D scanning in the presence of specularities. In each case, the 3D scanning technique used was phase-shifting of sinusoidal patterns. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br>
            <router-link :to="{path:'/projects/categories/project',query:{cid:this.$route.query.cid,pid:this.$route.query.pid}}">Back to Main Project Page</router-link>
        </td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Metal Hemisphere</strong></td>
                    <td><div align="right"><strong>Method: Phase-Shifting<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Hemisphere.JPG"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Hemisphere.JPG" width="328" height="202" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Glossy Metal Hemisphere</strong></div></td>

                  </tr>
                </tbody></table>
                  
              </td></tr><tr>
                <td>
                
                
                <table width="100%" border="0" cellspacing="1" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><br>
                          <span class="style1">.</span><br>
                          <strong>Input Images</strong></div></td>
                    <td width="35%"><div align="center"><br>
                      <span class="style1">.</span><br>
                      <strong>3D Reconstruction</strong></div></td>
                    </tr>
                </tbody></table>




                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Hemisphere-ND-3.gif"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Hemisphere-ND-3.gif" width="279" height="170" border="0"></a><br>
                          </div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Ball3DVis01-ND-HighExp00.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Ball3DVis01-ND-HighExp00.png" width="279" height="170" border="0"></a><br>
                      </div></td>
                    </tr>
                </tbody></table>


<table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center">Conventional structured light [large exposure]</div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    </tbody></table>
                
                
                
                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Hemisphere-ND-2.gif"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Hemisphere-ND-2.gif" width="279" height="170" border="0"></a><br>
                          </div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Ball3DVis01-ND-MedExp00.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Ball3DVis01-ND-MedExp00.png" width="279" height="170" border="0"></a><br>
                      </div></td>
                    </tr>
                </tbody></table>
                
                
                
                


                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center">Conventional structured light [medium (best) exposure]</div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    </tbody></table>


                
                
                
                
                
                
                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Hemisphere-ND-1.gif"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Hemisphere-ND-1.gif" width="279" height="170" border="0"></a><br>
                          </div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Ball3DVis01-ND-LowExp00.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Ball3DVis01-ND-LowExp00.png" width="279" height="170" border="0"></a><br>
                      </div></td>
                    </tr>
                </tbody></table>
                
                
                
                


                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center">Conventional structured light [small exposure]</div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    </tbody></table>


                
                
                
                
                
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td>Left column shows input images for conventional SL under large (1.6s),
medium (1.0s) and small (0.3s) exposure settings, respectively. Each of these sequences includes strong (saturated)
specularities, dark (noisy) regions, or both. Right column shows the corresponding reconstructed shapes. At high exposures, regions with specular highlights are not
reconstructed due to saturation. At low exposures, some scene regions are too dark to be reconstructed reliably.</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>
              
              <td>
              
              


                <table width="100%" border="0" cellspacing="1" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><br>
                          <span class="style1">.</span><br>
                          <strong>Input Images</strong></div></td>
                    <td width="35%"><div align="center"><br>
                      <span class="style1">.</span><br>
                      <strong>3D Reconstruction</strong></div></td>
                    </tr>
                </tbody></table>



              
              
              
              
              <table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Hemisphere-WD.gif"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Hemisphere-WD.gif" width="279" height="170" border="0"></a><br>
                          </div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Ball3DVis01-WD00.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/hemisphere/Ball3DVis01-WD00.png" width="279" height="170" border="0"></a><br>
                      </div></td>
                    </tr>
                </tbody></table>
                
                
                
                


                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center">Diffuse structured light [best exposure]</div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    </tbody></table>


                
                
                
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td>With the diffuser, the specularity is spread out and weakened in the input images. Also, the intensity of the points near the base plane is increased. Because of this, a higher quality reconstruction is achieved. </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
          </tbody></table></td>
          </tr>




<tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Coin</strong></td>
                    <td><div align="right"><strong>Method: Phase-Shifting<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
               <tr>

                <td> <table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/coin/IMAGE-WD-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/coin/IMAGE-WD-Processed.png" width="200" height="171" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/coin/Coin-ND-Exp006-S1-Flat00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/coin/Coin-ND-Exp006-S1-Flat00-Processed.png" width="200" height="171" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Conventional Structured Light</strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/coin/Quarter3DVis-WD-Flat-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/coin/Quarter3DVis-WD-Flat-Processed.png" width="200" height="171" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Diffuse Structured Light</strong></div></td>
                    </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td>Reconstructions using best exposures for both conventional and diffuse SL, respectively. Profile views of the reconstructions are shown. Since the coin is specular, conventional SL results in depth errors (large spikes) due to specular highlights and low signal-to-noise ratio. With the same number of input images, diffuse SL produces the correct reconstruction (flat). </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>

              <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/coin/Quarter3DVis-WD00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/coin/Quarter3DVis-WD00-Processed.png" width="279" height="240" border="0"></a><br>
                          </div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/coin/Quarter3DVis-WD01-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/coin/Quarter3DVis-WD01-Processed.png" width="279" height="240" border="0"></a><br>
                      </div></td>
                    </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center">Alternate view-point renderings of the reconstructions using diffuse SL.</div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
          </tbody></table></td>
          </tr>








          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Earring</strong></td>
                        <td width="53%"><div align="right"><strong>Method: Phase-Shifting<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Earring/IMG_6280.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Earring/IMG_6280.png" width="203" height="150" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Earring/EarRing3D-2-ND-HighestExp00.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Earring/EarRing3D-2-ND-HighestExp00.png" width="203" height="150" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Conventional Structured Light</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Earring/EarRing3D-2-WD00.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Earring/EarRing3D-2-WD00.png" width="203" height="150" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Diffuse Structured Light</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>The earring is made of polished (specular) copper. Reconstructions using best exposures for both conventional and diffuse SL, respectively. Because of specularities, conventional SL results in holes. With the same number of input images, diffuse SL produces a higher quality reconstruction. </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
                <tbody><tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="47%"><strong>Knife Edge</strong></td>
                        <td width="53%"><div align="right"><strong>Method: Phase-Shifting<br>
                        </strong></div></td>
                      </tr>
                    </tbody></table>
                      <div align="right"></div></td>
                </tr>
                <tr>
                  <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                      <tbody><tr>
                        <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Knife/Scene.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Knife/Scene.png" width="203" height="150" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Scene</strong></div></td>
                        <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Knife/KnifeEdge3DVis-ND-HighExp00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Knife/KnifeEdge3DVis-ND-HighExp00-Processed.png" width="203" height="150" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Conventional Structured Light</strong></div></td>
                        <td width="33%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Knife/KnifeEdge3DVis-WD00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/Knife/KnifeEdge3DVis-WD00-Processed.png" width="203" height="150" border="0"></a><br>
                                <span class="style1">.</span><br>
                                <strong>Diffuse Structured Light</strong></div></td>
                      </tr>
                    </tbody></table>
                      <table width="100%" border="0" cellspacing="10" cellpadding="0">
                        <tbody><tr>
                          <td>The knife blade is made of polished stainless steel. Conventional SL results in erroneous reconstructions due to specular highlights and low signal-to-noise ratio. Reconstruction using diffuse SL is nearly error-free. </td>
                        </tr>
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                    </tbody></table></td>
                </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>